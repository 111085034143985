export enum Domains {
  PROVINCIA = 'provincia',
  STATO_CLIENTE = 'statocliente',
  PERMESSO_AMMINISTRATORE = 'permessoamministratore',
  PERMESSO_OPERATORE = 'permessooperatore',
  STATO_OPERATORE = 'statooperatore',
  STATO_PACCHETTO = 'statopacchetto',
  STATO_RICHIESTA = 'statorichiesta',
  STATO_ESITOATTIVITA = 'statoesitoattivita',
  STATO_ESITOATTIVITARICHIESTA = 'statoesitoattivitarichiesta',
  STATTO_PROCEDIMENTO = 'statoprocedimento',
  TIPOLOGIA_GIUDIZIO = 'tipologiagiudizio',
  TIPOLOGIA_PREVISIONE = 'tipologiamovimentoprevisione',
  NATURA_PREVISIONE = 'naturaprevisione',
  STATO_ATTIVITA = 'statoattivita',
  STATO_ATTIVITARICHIESTA = 'statoattivitarichiesta',
  RUOLO_ATTIVITA = 'ruoloattivita',
  CATEGORIA_ATTIVITA = 'categoriaattivita',
  CATEGORIA_ATTIVITARICHIESTA = 'categoriaattivitarichiesta',
  FONTE_ATTIVITA = 'fonteattivita',
  FONTE_ATTIVITARICHIESTA = 'fonteattivitarichiesta',
  TIPOLOGIA_SCADENZA = 'tipologiascadenza',
  STATO_PROCEDURA = 'statoprocedura',
  ATTIVITA_DATA_RICHIESTA = 'adatr',
  ATTIVITA_DOCUMENTAZIONE_RICHIESTA = 'adocr',
  ATTIVITA_ULTIMO_ATTO_INTERRUTTIVO = 'auai',
  ATTIVITA_ULTERIORI_DATI = 'aud',
  MANDANTE_TIPOLOGIA = 'mat',
  MANDANTE_STATO = 'mas',
  RUOLO_OPERATORE_TIPOLOGIA = 'ruolooperatoretipologia',
  RUOLO_OPERATORE = 'ruolooperatore',
  PORTAFOGLIO_STATO = 'portafogliostato',
  FASCICOLO_STATO = 'fascicolostato',
  SOGGETTTO_DEBITORE_TIPOLOGIA = 'soggettodebitoretipologia',
  ELEMENTO_GBV = 'elementogbv',
  NAZIONE = 'nazione',
  SOGGETTTO_DEBITORE_CATEGORIA = 'soggettodebitorecategoria',
  TIPOLOGIA_LAVORO = 'tipologialavoro',
  LINEA_TIPOLOGIA = 'lineatipologia',
  LINEA_ESCUSSA_STATO = 'lineaescussastato',
  RUOLO_DEBITORIO = 'ruolodebitorio',
  VINCOLI_ASSET = 'vincoliasset',
  NATURE_VALORE_ASSET = 'naturavaloreasset',
  DIRITTO_PROPRIETA = 'dirittoproprieta',
  NATURA_TITOLO = 'naturatitolo',
  UFFICIO = 'ufficio',
  AUTORITA = 'autorita',
  GARANZIA_REALE_TIPOLOGIA = 'garanziarealetipologia',
  IPOTECA_NATURA = 'ipotecanatura',
  GARANZIA_PERSONALE_TIPOLOGIA = 'garanziapersonaletipologia',
  CAMBIALE_PERIODICATA = 'cambialeperiodicita',
  INTERVENTO_TIPOLOGIA = 'interventotipologia',
  AZIONE_ESECUTIVA_RUOLO = 'azioneesecutivaruolo',
  AZIONE_ORDINARIA_RUOLO = 'azioneordinariaruolo',
  TIPOLOGIA_UDIENZA = 'tipologiaudienza',
  TIPOLOGIA_SEZIONE = 'tipologiasezione',
  AZIONE_ORDINARIA_ESITO = 'azioneordinariaesito',
  ESITO_UDIENZA = 'esitoudienza',
  ADEMPIMENTO_UDIENZA = 'adempimentoudienza',
  TIPOLOGIA_ASTA = 'tipologiaasta',
  ESITO_ASTA = 'esitoasta',
  ESITO_AGGIUDICAZIONE = 'esitoaggiudicazione',
  ALTRA_PROCEDURA_TIPOLOGIA_INSINUA = 'altraproceduratipologiainsinua',
  ALTRA_PROCEDURA_RUOLO = 'altraproceduraruolo',
  LAVORAZIONE_ATTIVITA_STATO = 'lavorazioneattivitastato',
  FASCICOLO_FILE_CATEGORIA = 'fascicolofilecategoria',
  TIPOLOGIA_REGISTRO = 'tipologiaregistro',
  TIPOLOGIA_EVENTO = 'tipologiaevento',
  VALIDAZIONE_ATTRIBUTI = 'validazioneattributi',
  ESITO_ACCORDO_TRANSATTIVO = 'esitoaccordotransattivo',
  NOTIFICA_TIPOLOGIA = 'notificatipologia',
  ATTIVITA_SPESA = 'attivitaspesa',
  LAVORAZIONE_SPESA_TIPOLOGIA = 'lavorazionespesatipologia',
  LAVORAZIONE_SPESA_STATO = 'lavorazionespesastato',
  DICHIARAZIONE_PRESSO_TERZI = 'dichiarazionepressoterzi',
  ATTIVITA_RICHESTA_SPESA = 'attivitarichiestaspesa',
  LIVELLO_AUTORIZZATIVO = 'livelloautorizzativo',
  TIPOLOGIA_LAVORAZIONE_FASCICOLO = 'tipologialavorazionefascicolo',
  TIPOLOGIA_DOCUMENTO = 'tipologiadocumentazionerichiesta',
  CATEGORIZZAZIONE_TIPIZZAZIONE = 'fascicolofilecategoriatipizzazione',
  ELABORAZIONE_FEEDBACK_STATO = 'fascicolofileelaborazionefeedbackstato',
}

export type DomainItem = Record<string, string> & {
  id: number;
  nome: string;
};
