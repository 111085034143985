import {
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  Backdrop,
  Card,
  Box,
} from '@mui/material';
import { useMemo } from 'react';

import { PreviewContent } from './content';
import { PreviewHeader } from './header';
import { PreviewPdf } from './pdf-viewer';
import { PreviewCardCSS } from './styles';

export type PreviewDialogProps = {
  link: string;
  onClose: () => void;
  open?: boolean;
  disablePortal?: boolean;
  fileType?: string;
  canDownload?: boolean;
};

export function PreviewDialog(props: PreviewDialogProps) {
  const fileName =
    props.link
      ?.split('/')
      .pop()
      ?.split('_')
      .filter((_, i) => i)
      ?.join('_') ?? props.link;
  const fileLink = props.link;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const renderBodyError = useMemo(() => {
    const fileType = fileLink.split('.').pop();
    if (fileType === 'pdf') {
      return (
        <PreviewPdf fileLink={fileLink} nHeight="79vh" />
      );
    }

    return (
      <PreviewContent
        canDownload={props.canDownload}
        fileLink={fileLink}
        fileType={props.fileType}
        height="calc(100% - 65px)"
      />
    );
  }, [fileLink, props.canDownload, props.fileType]);

  if (props.disablePortal) {
    return (
      <Box>
        <Backdrop sx={{ position: 'absolute' }} open />
        <Card elevation={5} sx={PreviewCardCSS}>
          <Box p={2}>
            <PreviewHeader fileName={fileName} onClose={props.onClose} />
          </Box>
          <Divider />
          {renderBodyError}
        </Card>
      </Box>
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const renderBody = useMemo(() => {
    const fileType = fileLink.split('.').pop();
    if (fileType === 'pdf') {
      return (
        <PreviewPdf fileLink={fileLink} nHeight="76vh" />
      );
    }

    return (
      <PreviewContent
        canDownload={props.canDownload}
        fileLink={fileLink}
        fileType={props.fileType}
        height={700}
      />
    );
  }, [fileLink, props.canDownload, props.fileType]);

  return (
    <Dialog
      maxWidth={false}
      open={!!props.open}
      fullWidth
      onClose={props.onClose}
    >
      <DialogTitle component="div">
        <PreviewHeader fileName={fileName} onClose={props.onClose} />
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 0 }}>
        {renderBody}
      </DialogContent>
    </Dialog>
  );
}
